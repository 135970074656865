export function init() {
	'use strict';

	const $slides = $('.js-contact-slide');
	const $sliderNext = $('.js-contact-next');
	const $sliderPrev = $('.js-contact-prev');
	const time = 1000;

	if ($slides.length && $sliderNext.length && $sliderPrev.length) {

		const slidesNum = $slides.length - 1;
		let $currentSlide = $slides.first();
		let currentNum = $currentSlide.index();

		const checkMinMax = function() {
			if (!currentNum) {
				$sliderPrev.addClass('contact-map__control--inactive');
			} else {
				$sliderPrev.removeClass('contact-map__control--inactive');
			}

			if (currentNum === slidesNum) {
				$sliderNext.addClass('contact-map__control--inactive');
			} else {
				$sliderNext.removeClass('contact-map__control--inactive');
			}

		};

		checkMinMax();

		$sliderNext.on('click', function() {
			
			if (currentNum < slidesNum) {
				$currentSlide.removeClass('is-active');

				$currentSlide = $currentSlide.next();

				setTimeout(() => {
					currentNum = $currentSlide.index();
					$slides.each(function() {
						const $slide = $(this);
						if ($slide.not($currentSlide) && $slide.index() < currentNum) {
							$slide.removeClass('contact-map__slide--after');
							$slide.addClass('contact-map__slide--before');
						}
					});
					checkMinMax();
				}, 0);

				setTimeout(() => {
					$currentSlide.removeClass('contact-map__slide--after');
					$currentSlide.removeClass('contact-map__slide--before');
					$currentSlide.addClass('is-active');
				}, time/2);
			}
		});

		$sliderPrev.on('click', function() {
			
			if (currentNum > 0) {
				$currentSlide.removeClass('is-active');

				$currentSlide = $currentSlide.prev();

				setTimeout(() => {
					currentNum = $currentSlide.index();
					$slides.each(function() {
						const $slide = $(this);
						if ($slide.not($currentSlide) && $slide.index() > currentNum) {
							$slide.addClass('contact-map__slide--after');
							$slide.removeClass('contact-map__slide--before');
						}
					});
					checkMinMax();
				}, 0);

				setTimeout(() => {
					$currentSlide.removeClass('contact-map__slide--after');
					$currentSlide.removeClass('contact-map__slide--before');
					$currentSlide.addClass('is-active');
				}, time/2);
			}
		});

	}

}