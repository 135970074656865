export function init() {
	'use strict';

	const $doubleClick = $('.js-double-click');

	if ($doubleClick.length && $('body').hasClass('md-mobile')) {
		$doubleClick.on('click', function(e) {
			e.preventDefault();
			$doubleClick.toggleClass('is-active');
			const $subnav = $doubleClick.next('.site-nav__subnav');
			$subnav.slideToggle();
		});

		if ($doubleClick.hasClass('is-active')) {
			$doubleClick.trigger('click').addClass('is-active');
		}
	}

}