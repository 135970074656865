require('headjs');
require('jquery');
require('jquerymigrate');

import * as classListPolyFill from './helpers/classListPolyFill';
import * as parallaxSlider from './functions/parallaxSlider';
import * as cookie from './functions/cookie';
import * as filters from './functions/filters';
import * as viewer from './functions/viewer';
import * as header from './functions/header';
import * as imgMove from './functions/imgMove';
import * as contact from './functions/contact';
import * as weDo from './functions/weDo';
import * as pageTransitions from './functions/pageTransitions';
import * as mobileNav from './functions/mobileNav';
import * as doubleClick from './functions/doubleClick';
import * as paginatorWork from './functions/paginatorWork';
import * as wave from './functions/wave';

// import * as buttonHover from './functions/buttonHover';
// import * as maps from './functions/maps';
// import * as slicks from './functions/slicks';

// Starter packs
classListPolyFill.init();

if(navigator.userAgent.match('CriOS')) {
    document.querySelector('html').classList.add('ios');
}

$(function() {
    'use strict';

    const userAgent = window.navigator.userAgent;

    if ($('html').hasClass('ios') && $(window).outerWidth() < 489 && userAgent.match(/iPhone/i)) {
        const $homeSection = $('.js-home-section');
        if ($homeSection.length) {
            $homeSection.addClass('l-section--safari').css({
                height: $(window).outerHeight()
            });
        }

        const $wdSection = $('.js-wedo-slider');
        if ($wdSection.length) {
            $wdSection.addClass('l-section--safari').css({
                height: '100%'
            });
        }

    }

    mobileNav.init();

    pageTransitions.init();

    imgMove.init();

    cookie.init();

    header.init();

    filters.init();

    paginatorWork.init();

    viewer.init();

    contact.init();

    weDo.init();

    doubleClick.init();

    if ($('.js-contact-wave').length) {
        wave.init($('.js-contact-wave .js-wave'));
    }

    $('.js-disabled').on('click', function(e) {
        e.preventDefault();
    });

    // LOADER FULL LOAD
    const $loader = $('#loader');
    if ($loader.length) {
        const $homeBody = $('.js-home-section');
        $(document).on('is-loader-ended', function() {
    	   $homeBody.removeClass('l-section--hidden');

            parallaxSlider.init();
        });

        setTimeout(() => {
           moveBar(100, true);
        }, 0);
	}

    window.onunload = function(){};

});