export function init() {
	'use strict';

	const $mobileNav = $('.js-mobie-nav');
	const $mobileTrigger = $('.js-mobile-trigger');
	const $body = $('.js-site-body');

	if ($mobileNav.length && $mobileTrigger.length) {

		$mobileTrigger.on('click', function() {
			$(this).toggleClass('site-top__menu--open');
			$mobileNav.toggleClass('site-top__hld--open');
			$body.toggleClass('site-body--open');
		});

	}

}