import * as paginatorWork from './paginatorWork';
export function init() {
	'use strict';

	let isLoading = false;
	const $window = $(window);

	// FILTERS
	const $filtersHld = $('.js-filters');

	if ($filtersHld.length) {
		const $filters = $filtersHld.find('.js-filter');

		$filters.each(function() {
			const $filter = $(this);
			const $filterText = $filter.find('.js-filter-text');
			const $filterValues = $filter.find('.js-filter-value');
			const $filterInput = $filter.find('.js-filter-input');

			$filter.on('click', function() {
				if (!isLoading) {
					if ($filter.hasClass('is-active')) {
						$filter.removeClass('is-active');
					} else {
						$filters.not($filter).removeClass('is-active');
						$filter.addClass('is-active');	
					}
				}
			});

			$filterValues.on('click', function() {
				if (!isLoading) {
					const $filterValue = $(this);
					const filterValue = $filterValue.attr('data-value');
					const filterText = $filterValue.attr('data-text');

					$filterText.text(filterText);
					$filterInput.val(filterValue);

					$filterValues.not($filterValue).removeClass('is-active');
					$filterValue.addClass('is-active');

					$window.trigger('reload');
				}
			});
		});

	}

	// SEARCH
	const $search = $('.js-search');
	const $searchSubmit = $('.js-search-submit');

	if ($search.length && $searchSubmit.length) {
		
		$search.on('keyup', function(e) {
			e.preventDefault();
			if (e.keyCode === 13) {
				$searchSubmit.trigger('click');
			}
		});

		$searchSubmit.on('click', function() {
			// const value = $search.val();
			$window.trigger('reload');
		});
	}

	// LOAD
	const $holder = $('.js-infos-holder');
	const $filters = $('.js-filter-input');
	if ($holder.length) {

		const url = $holder.attr('data-url');

		$window.on('reload', function() {

			// GET VAL FROM SEARCH
			const search = $search.val();

			// GET VAL FROM FILTERS
			let filterString = '';
			$filters.each(function() {
				const $filter = $(this);
				const param = $filter.attr('data-filter');
				const value = $filter.val();
				if (param && value) {
					filterString += '&'+param+'='+value;
				}
			});

			$holder.addClass('is-loading');

			$holder.load(url+'?Search='+search+filterString+'&page=1 .js-infos-holder > *', function() {
				paginatorWork.init();
				setTimeout(() => {
					$holder.removeClass('is-loading');
				}, 300);
			});

		});

	}



}