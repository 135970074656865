export function init() {
	'use strict';

	const isHome = $('.js-home-slider').length || $('.js-wd-slider').length;
	const $header = $('.js-site-top');
	
	if (!isHome && $header.length) {
		const checkThreshold = 40;

		const checkIt = function() {
		    $(window).scrollTop() > checkThreshold ? $header.addClass('site-top--dark') : $header.removeClass('site-top--dark');
		};

		checkIt();

		$(window).on('scroll resize', function() {
		    checkIt();
		});
	}
}