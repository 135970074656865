export function init() {
	'use strict';

	const $hBs = $('.js-homebottom-item');

	if ($hBs.length) {

		const getRandomInt = function(min, max) {
		    return Math.floor(Math.random() * (max - min + 1)) + min;
		};

		$hBs.each(function() {
			const $hB = $(this);
			const $hBPhotos = $hB.find('.js-homebottom-photo');

			if ($hBPhotos.length > 1) {

				const hbTime = getRandomInt(1000, 10000);

				setTimeout(() => {
					$hBPhotos.first().addClass('is-inactive');
					$hBPhotos.last().removeClass('is-inactive');
				}, hbTime);

			}

		});
	}

}