require('slick');

export function init() {
	'use strict';

	const $slider = $('.js-wedo-slider');
	const $slides = $('.js-wedo-slide');
	const slidesNum = $slides.length -1 ;
	const $dots = $('.js-wedo-dot');
	const $scroll = $('.js-wedo-scroll');
	const $footer = $('.js-site-footer');
	const $homeImgs = $('.js-wedo-imgs');
	const $header = $('.js-site-top');
	const $vids = $('.js-wedo-vid');

	const $body = $('.js-site-body');


	let currentNum = 0;
	let isAnimating = false;
	const slickTime = 800;

	if ($slider.length && slidesNum && $scroll.length) {

		const firstVid = $vids[0];
		let firstFlag = true;

		if (firstVid) {
			const playPromise = firstVid.play();

			  if (playPromise !== undefined) {
			    playPromise.then(() => {
			    	
			    })
			    .catch(() => {
			    	$(window).on('click scroll mouseover load change', function() {
			    		if (firstFlag) {
			    			firstFlag = false;
			    			firstVid.play();
			    		}
			    	});
			    });
			}
		}

		$slider.on('init.slick', function() {
			setTimeout(() => {
				$slider.slick('slickGoTo', parseInt($slider.attr('data-start')));
				setTimeout(() => {
					$slider.removeClass('is-hidden');
				}, 760);
			}, 0);
		});

		// SLIDER
		// $slider.slick({
		// 	slide: '.js-wedo-slide',
	 //        accessibility: false,
	 //        autoplay: false,
	 //        arrows: false,
	 //        dots: false,
	 //        easing: 'linear',
	 //        fade: false,
	 //        infinite: false,
	 //        pauseOnHover: true,
	 //        slidesToShow: 1,
	 //        speed: slickTime,
	 //        swipe: false,
	 //        vertical: true
	 //    });

	 	$slider.slick({
	 		slide: '.js-wedo-slide',
	        accessibility: false,
	        autoplay: false,
	        arrows: false,
	        dots: false,
	        easing: 'linear',
	        fade: true,
	        infinite: false,
	        slidesToShow: 1,
	        speed: slickTime,
	        swipe: false,
	        vertical: true
	     });


		// BEFORECHANGE
	    $slider.on('beforeChange', function(e, slick, currentSlide, nextSlide) {

	    	isAnimating = true;

	    	$slider.addClass('we-do--animating');

	    	if (currentSlide < nextSlide) {
	    		$slider.removeClass('we-do--down');
	    		$slider.addClass('we-do--up');
	    	} else {
	    		$slider.removeClass('we-do--up');
	    		$slider.addClass('we-do--down');
	    	}

	    	// DOTS
	    	const $dot = $dots.eq(nextSlide);
	    	$dots.not($dot).removeClass('is-active');
	    	$dot.addClass('is-active');

	    	// SCROLL
	    	if (nextSlide === slidesNum) {
	    		$scroll.addClass('is-hidden');
	    		$footer.addClass('is-active');
	    	} else {
	    		$scroll.removeClass('is-hidden');
	    		$footer.removeClass('is-active');
	    	}

	    	if (nextSlide) {
	    		$scroll.addClass('h-scroll--white');
	    	} else {
	    		$scroll.removeClass('h-scroll--white');
	    	}

	    	// HOMEIMGS
	    	if (nextSlide && nextSlide !== slidesNum) {
	    		// IMGS
	    		const $homeImg = $homeImgs.eq(nextSlide - 1);
	    		$homeImgs.not($homeImg).removeClass('is-active');
	    		setTimeout(() => {
	    			$homeImg.addClass('is-active');
	    		}, slickTime/2);
	    	} else {
	    		// IMGS
	    		$homeImgs.removeClass('is-active');
	    	}

	    	if (nextSlide) {
	    		setTimeout(() => {
		    		$header.addClass('site-top--white');
	    		}, slickTime - 200);
		    	$dots.addClass('we-do__navigation-info-item--white');
	    	} else {
	    		$header.removeClass('site-top--white');
	    		$dots.removeClass('we-do__navigation-info-item--white');
	    	}

	    	const $currentSlide = $slides.eq(currentSlide);
	    	const bgc = $currentSlide.attr('data-color');
	    	$body.css({
	    		'background-color': bgc
	    	});

	    	// VIDEO
	    	const currentVid = $vids[currentSlide];
	    	if (currentVid) {
	    		currentVid.pause();
	    	}
	    });

	    // AFTERCHANGE
	    $slider.on('afterChange', function(e, slick, currentSlide) {
	    	$slider.removeClass('we-do--animating');
	    	currentNum = currentSlide;
	    	isAnimating = false;
	    	// VIDEO
	    	const currentVid = $vids[currentSlide];
	    	if (currentVid) {
	    		currentVid.play();
	    	}

	    	const $currentSlide = $slides.eq(currentSlide);
	    	const bgc = $currentSlide.attr('data-color');
	    	$body.css({
	    		'background-color': bgc
	    	});
	    });

		// SCROLL
	    window.addEventListener('wheel', function(e) {
	    	if (Math.abs(e.deltaY) > 10) {
				if (e.deltaY < 0) {
					// SLICK GO TO
					if (currentNum > 0) {
						$slider.slick('slickPrev');
					}
				}
				if (e.deltaY > 0) {
					// SLICK GO TO
					if (currentNum < slidesNum) {
						$slider.slick('slickNext');
					}
				}
	    	}

	    });

	    // DOTS
	    if ($dots.length) {
	    	$dots.on('click', function() {
	    		if (!isAnimating) {
		    		const $dot = $(this);
		    		if (!$dot.hasClass('is-active')) {
		    			const dotIndex = $dot.index();
		    			$slider.slick('slickGoTo', dotIndex);
		    		}
	    		}
	    	});
	    }

	    // SCROLL
	    $scroll.on('click', function() {
	    	if (currentNum < slidesNum) {
	    		$slider.slick('slickNext');
	    	}
	    });

	    // SWIPE
	    let touchstartY = 0;
	    let touchendY = 0;

	    const handleSwipe = function() {
	    	if (touchendY + 50 < touchstartY) {
		        $slider.slick('slickNext');
		    }
		    if (touchendY > touchstartY + 50) {
		        $slider.slick('slickPrev');
		    }
	    };

	    $(window).on('touchstart', function(e) {
	    	touchstartY = e.originalEvent.touches[0].clientY;
	    });
	    $(window).on('touchend', function(e) {
	    	touchendY = e.originalEvent.changedTouches[0].clientY;
	    	handleSwipe();
	    });

	    // KEYPRESS
	    $(document).keydown(function(e){
	    	if (e.keyCode === 38) {
    	        $slider.slick('slickPrev');
    	    }
    	    else if (e.keyCode === 40) {
    	        $slider.slick('slickNext');
    	    }
	    });


	}
}