require('slick');
import * as hB from './hB';
import * as wave from './wave';

export function init() {
	'use strict';

	const $header = $('.js-site-top');
	const $body = $('.js-site-body');

	const $slider = $('.js-home-slider');
	const $slides = $('.js-home-slide');
	const slidesNum = $slides.length -1 ;
	const $dotsHld = $('.js-slider-dots');
	const $dots = $('.js-slider-dot');
	const $scroll = $('.js-home-scroll');
	const $footer = $('.js-site-footer');
	const $homeImgs = $('.js-home-imgs');
	const $scrollText = $('.js-scroll-title');

	const $anim = $('.js-home-anim');
	const $cookie = $('.js-cookie');

	let hbToFire = true;
	let currentNum = 0;
	let isAnimating = false;
	let isReady = true;
	const slickTime = 800;

	if ($slider.length && slidesNum && $scroll) {

		const normalText = $scrollText.attr('data-text');
		const scrollText = $scrollText.attr('data-scroll-text');

		setTimeout(() => {
			isReady = true;
			$scroll.removeClass('is-hidden');
			$dotsHld.removeClass('is-hidden');
		}, 1500);

	    // INIT
	    $slider.on('init', function() {

	    	$anim.addClass('is-animating');

    		// SCROLL
    	    window.addEventListener('wheel', function(e) {

    	    	if (isReady && !isAnimating) {
    	    		if (Math.abs(e.deltaY) > 10) {
	    				if (e.deltaY < 0) {
	    					// SLICK GO TO
	    					if (currentNum > 0) {
	    						$slider.slick('slickPrev');
	    					}
	    				}
	    				if (e.deltaY > 0) {
	    					// SLICK GO TO
	    					if (currentNum < slidesNum) {
	    						$slider.slick('slickNext');
	    					}
	    				}
	    			}
    	    	}

    	    });

    	    // DOTS
    	    if ($dots.length) {
    	    	$dots.on('click', function() {
    	    		if (isReady && !isAnimating) {
    		    		const $dot = $(this);
    		    		if (!$dot.hasClass('is-active')) {
    		    			const dotIndex = $dot.index();
    		    			$slider.slick('slickGoTo', dotIndex);
    		    		}
    	    		}
    	    	});
    	    }

    	    // SCROLL
    	    $scroll.on('click', function() {
    	    	if (isReady && !isAnimating && currentNum < slidesNum) {
    	    		$slider.slick('slickNext');
    	    	}
    	    });

    	    // KEYPRESS
    	    $(document).keydown(function(e){
    	    	if (e.keyCode === 38) {
	    	        $slider.slick('slickPrev');
	    	    }
	    	    else if (e.keyCode === 40) {
	    	        $slider.slick('slickNext');
	    	    }
    	    });

    	    // SWIPE
    	    let touchstartY = 0;
    	    let touchendY = 0;

    	    const handleSwipe = function() {
    	    	if (touchendY + 50 < touchstartY) {
	    	        $slider.slick('slickNext');
	    	    }
	    	    if (touchendY > touchstartY + 50) {
	    	        $slider.slick('slickPrev');
	    	    }
    	    };

    	    $(window).on('touchstart', function(e) {
    	    	touchstartY = e.originalEvent.touches[0].clientY;
    	    });
    	    $(window).on('touchend', function(e) {
    	    	touchendY = e.originalEvent.changedTouches[0].clientY;
    	    	handleSwipe();
    	    });
	    });

		// SLIDER
		$slider.slick({
	        accessibility: false,
	        autoplay: false,
	        arrows: false,
	        dots: false,
	        easing: 'linear',
	        fade: true,
	        infinite: false,
	        slidesToShow: 1,
	        speed: slickTime,
	        swipe: false,
	        vertical: true
	    });


		// BEFORECHANGE
	    $slider.on('beforeChange', function(e, slick, currentSlide, nextSlide) {

	    	isAnimating = false;
	    	
	    	if (nextSlide === slidesNum) {
	    		setTimeout(() => {
	    			$header.removeClass('site-top--unbordered');
	    			$header.addClass('site-top--gradient');
	    		}, slickTime);
	    	} else {
	    		$header.addClass('site-top--unbordered');
	    		$header.removeClass('site-top--gradient');
	    	}

	    	$slider.addClass('hs--animating');

	    	if (currentSlide < nextSlide) {
	    		$slider.removeClass('hs--down');
	    		$slider.addClass('hs--up');
	    	} else {
	    		$slider.removeClass('hs--up');
	    		$slider.addClass('hs--down');
	    	}

	    	// DOTS
	    	const $dot = $dots.eq(nextSlide);
	    	$dots.not($dot).removeClass('is-active');
	    	$dot.addClass('is-active');

	    	// SCROLL
	    	if (nextSlide === slidesNum) {
	    		$scroll.addClass('is-hidden');
	    		$footer.addClass('is-active');
	    	} else {
	    		$scroll.removeClass('is-hidden');
	    		$footer.removeClass('is-active');
	    	}

	    	// HOMEIMGS
	    	if (nextSlide && nextSlide !== slidesNum) {
	    		$header.addClass('site-top--black');
	    		// IMGS
	    		const $homeImg = $homeImgs.eq(nextSlide - 1);
	    		$homeImgs.not($homeImg).removeClass('is-active');
	    		setTimeout(() => {
	    			$homeImg.addClass('is-active');
	    		}, slickTime/2);
	    	} else {
	    		if (!nextSlide) {
	    			$header.removeClass('site-top--black');
	    		}
	    		// IMGS
	    		$homeImgs.removeClass('is-active');
	    	}

	    	// LETTERS
	    	const $currentSlide = $slides.eq(currentSlide);
	    	const bgc = $currentSlide.attr('data-color');
	    	$body.css({
	    		'background-color': bgc
	    	});
	    	const $currentLetters = $currentSlide.find('.js-hp-letter');
	    	const currentLettersLength = $currentLetters.length;

	    	if (currentLettersLength) {

	    		const $currentRest = $currentSlide.find('.js-hp-rest');

	    		$currentLetters.each(function(index) {
	    			const $currentLetter = $(this);
	    			setTimeout(() => {
	    				$currentLetter.removeClass('is-active');
	    			}, index * 150);
	    		});

	    		$currentRest.removeClass('is-active');
	    	}

	    	// SCROLL
		    if (nextSlide && !$scrollText.hasClass('js-scrolled')) {
	    		$scrollText.addClass('js-scrolled');
	    		$scrollText.addClass('is-hidden');
	    		setTimeout(() => {
	    			$scrollText.text(scrollText);
	    		}, 200);
	    	} else if (!nextSlide) {
	    		$scrollText.removeClass('js-scrolled');
	    		$scrollText.addClass('is-hidden');
	    		setTimeout(() => {
	    			$scrollText.text(normalText);
	    		}, 200);
	    	}

	    	// COOKIE
	    	if (nextSlide) {
	    		$cookie.addClass('is-hidden');
	    	} else {
	    		$cookie.removeClass('is-hidden');
	    	}
	    });

	    // AFTERCHANGE
	    $slider.on('afterChange', function(e, slick, currentSlide) {
	    	$slider.removeClass('hs--animating');
	    	currentNum = currentSlide;

	    	if (hbToFire && currentSlide === slidesNum) {
	    		hbToFire = false;
	    		hB.init();
	    	}

	    	// LETTERS
	    	const $currentSlide = $slides.eq(currentSlide);

	    	const $currentLetters = $currentSlide.find('.js-hp-letter');
	    	const currentLettersLength = $currentLetters.length;

	    	if (currentLettersLength) {

	    		const $currentRest = $currentSlide.find('.js-hp-rest');

	    		$currentLetters.each(function(index) {
	    			const $currentLetter = $(this);
	    			setTimeout(() => {
	    				$currentLetter.addClass('is-active');
	    			}, index * 150);
	    		});

	    		$currentRest.addClass('is-active');
	    		// setTimeout(() => {
	    		// 	isAnimating = false;
	    		// }, 50);
	    	} else {
	    		isAnimating = false;
	    	}

	    	$scrollText.removeClass('is-hidden');


	    	// WAVE
	    	const $wave = $currentSlide.find('.js-wave');
	    	if ($wave.length) {
	    		wave.init($wave);
	    	}

	    });

	}
}