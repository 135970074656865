export function init() {
	'use strict';

	const $body = $('.js-site-body');
	const $links = $('.js-inner-link');

	if ($body.hasClass('md-desktop') && $links.length) {

		setTimeout(() => {
			$body.removeClass('is-hidden');
		}, 200);

		$links.on('click', function(e) {
			e.preventDefault();

			const $link = $(this);

			$body.addClass('is-hidden');

			setTimeout(() => {
				window.location.href = $link.attr('href');
			}, 300);
		});

	}

}