export function init() {
	'use strict';

	const $links = $('.js-paginator-link');
	const linksNum = $links.length;
	const $current = $('.js-paginator-current');
	const windowWidth = $(window).outerWidth();

	let linksLimit = 8;

	if (windowWidth < 768) {
		linksLimit = 3;
	}

	if (linksNum > linksLimit && $current.length) {
		const $blank = $('<div class="paginator__link js-paginator-link paginator__link--blank"><span>...</span></div>');
		let overFlag = false;
		let underFlag = false;

		// GET DIFF VALUE
		let diffValue = 3;
		if (windowWidth < 768)  {
			diffValue = 1;
		}

		let currentIndex = $current.index();
		
		if (currentIndex === 0) {
			currentIndex = 1;
		} else if (currentIndex === linksNum - 1) {
			currentIndex = linksNum - 2;
		}

		$links.each(function() {
			const $link = $(this);
			const linkIndex = $link.index();

			if (linkIndex > 0 && linkIndex < linksNum - 1) {
				if (Math.abs(currentIndex - linkIndex) > diffValue) {

					if (currentIndex - linkIndex < 0) {
						if (!overFlag) {
							overFlag = true;
						}
					} else {
						if (!underFlag) {
							underFlag = true;
						}
					}
					$link.addClass('js-to-remove');
				}
			}
		});

		const $toRemove = $('.js-to-remove');
		if ($toRemove.length) {
			$toRemove.remove();
		}

		if (overFlag) {
			$blank.insertBefore($links.eq(linksNum - 1));
		}
		if (underFlag) {
			$blank.insertAfter($links.eq(0));
		}

	}
}