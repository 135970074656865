import Viewer from 'viewerjs';
export function init() {
	'use strict';

	const photo = document.getElementById('detailsPhoto');
	const $photo = $('#detailsPhoto');

	if (photo && $photo.length) {
		const viewer = new Viewer(photo, {
			inline: false,
			navbar: false,
			title: false,
			toolbar: {
				zoomIn: 4,
				zoomOut: 4,
				oneToOne: 4,
				reset: 0,
				prev: 0,
				play: 0,
				next: 0,
				rotateLeft: 0,
				rotateRight: 0,
				flipHorizontal: 0,
				flipVertical: 0
			},
			viewed: function() {
				viewer.move(1, 1);
			}
		});

		const $trigger = $('.js-trigger-max');
		if ($trigger.length) {
			$trigger.on('click', function() {
				$('#detailsPhoto').trigger('click');
			});
		}

	}
}