export function init($wave) {
	'use strict';

	if ($wave && !$wave.hasClass('is-fired') && $(window).outerWidth() > 1200) {

		$wave.addClass('is-fired');

		const getRandomInt = function(min, max) {
		    return Math.floor(Math.random() * (max - min + 1)) + min;
		};

		const $lines = $wave.find('g');
		const linesLength = $lines.length;

		if (linesLength) {

			let lineTime = 0;

			$lines.each(function() {
				const $line = $(this);
				
				lineTime += getRandomInt(20, 60);
				
				// const lineTime = getRandomInt(0, 10000);
				
				setTimeout(function() {
					$line.attr('class', 'is-visible');
				}, lineTime);

			});

			// setTimeout(function() {
			// 	setInterval(() => {
			// 		const lineIndex = getRandomInt(1, linesLength);
			// 		const $currentLine = $lines.eq(lineIndex);
			// 		if ($currentLine.attr('class')) {
			// 			$currentLine.attr('class', '');
			// 		} else {
			// 			$currentLine.attr('class', 'is-visible');
			// 		}
			// 	}, 300);
			// }, lineTime);

		}

	}

}