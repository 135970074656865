export function init() {
	'use strict';

	const $imgsHld = $('.js-home-info');

	if ($imgsHld.length && $(window).outerWidth() > 1240) {

		const xCap = 80;
		const yCap = 80;

		let windowWidth = 0;
		let windowHeight = 0;
		let windowWidthMiddle = 0;
		let windowHeightMiddle = 0;

		const calculateSize = function() {
			windowWidth = $(window).outerWidth();
			windowHeight = $(window).outerHeight();
			windowWidthMiddle = windowWidth/2;
			windowHeightMiddle = windowHeight/2;
		};

		calculateSize();

		$(window).on('resize', function() {
			calculateSize();
		});

		$imgsHld.each(function() {

			const $img1 = $imgsHld.find('.js-hp-1');
			const $img2 = $imgsHld.find('.js-hp-2');
			const $img3 = $imgsHld.find('.js-hp-3');

			$(window).on('mousemove', function(e) {
				const X = (windowWidthMiddle - e.clientX)/windowWidthMiddle * xCap;
				const Y = (windowHeightMiddle - e.clientY)/windowHeightMiddle * yCap;
				
				$img1.css({
					'transform' : 'translate('+X/2+'px,'+Y/2+'px)'
				});
				$img2.css({
					'transform' : 'translate('+X+'px,'+Y+'px)'
				});
				if ($img3.length) {
					$img3.css({
						'transform' : 'translate('+X*1.2+'px,'+Y*1.2+'px)'
					});
				}
			});

		});

	}

}